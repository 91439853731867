
.title {
  margin-left: 30%;
  background-color: #f7f7f7;
  border-radius: 1px solid #b1b1b1;
  margin-right: 30%;
  margin-top: 10%;
  padding-top: 1%;
  padding-bottom: 2%;
}

.title h3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

input {
  padding:2% 0;
   
}

.btn {
  text-align: center;
}

button {
  border: none;
  color: #fff;
  padding: 10px 25px;
  background-color: #0069D9;
  border-radius: 5px;
}
.customerid {
  margin-left: 36px;
}
.error {
  color: red;
}


